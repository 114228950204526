import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IFCondition from "../../../components/Commons/IFCondition/IFCondition";
import Loading from "../../../components/Commons/Loading/Loading";
import { getYear } from "../../../utils/dateForm";
import API from "../../../services/Api";
import { handleValidation } from "./constants";
import { toast } from "react-toastify";

const Recover = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [events, setEvents] = useState({
    failed: "",
    loading: false,
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: handleValidation,
    onSubmit: (values) => {
      const body = {...values};
      delete body.passwordConfirmation;
      setEvents({ loading: true, failed: "" });
      API.post('/api/v1/auth/passwordconfirm', { ...body })
      .then(res => {
        toast.success("Contraseña restablecida exitosamente, ya puedes iniciar sesión.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/auth/login");
      })
      .catch(err => setEvents({failed: err.response.data.error.user_message}));
    },
  });

  useEffect(() => {
    if (!state || !state.email) {
      return navigate("/auth/login");
    }
    formik.setFieldValue("email", state.email);
  }, [state]);

  return (
    <div className="home container-fluid">
      <div className="row no-gutter">
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div className="col-md-8 col-lg-6 bg-white">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <Link to="/">
                    <img
                      src={require("../../../assets/img/WongPrime1.1.png")}
                      alt="wongprime"
                      width="150px"
                      style={{ margin: "0 auto", display: "block" }}
                    />
                  </Link>
                  <h4 className="text-center mt-4">Recupera tu contraseña</h4>
                  <div
                    className="alert alert-success fade show mt-4"
                    role="alert" >
                    <strong>
                      <i className="fa fa-check-circle"></i>
                      &nbsp;Operación exitosa.
                    </strong>
                    <br />
                    Hemos enviado un correo de recuperación a tu correo {state?.email}.
                  </div>
                  <form onSubmit={formik.handleSubmit} className="mt-4">
                    <div className="form-label-group mb-2">
                      <label htmlFor="code">Código</label>
                      <input
                        type="text"
                        name="code"
                        id="code"
                        onChange={formik.handleChange}
                        value={formik.values.code}
                        className="form-control"
                        placeholder="000000"
                      />
                      {formik.errors.code && (
                        <div className="text-danger">
                          {formik.errors.code}
                        </div>) }
                    </div>
                    <div className="form-label-group mb-2">
                      <label htmlFor="password">Contraseña</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        className="form-control"
                      />
                      {formik.errors.password && (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>) }
                      <small className="form-text text-muted">
                        Tu contraseña debe tener entre 8 y 12 caracteres
                      </small>
                    </div>
                    <div className="form-label-group mb-2">
                      <label htmlFor="passwordConfirmation">
                        Confirmar contraseña
                      </label>
                      <input
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        onChange={formik.handleChange}
                        value={formik.values.passwordConfirmation}
                        className="form-control"
                        required
                      />
                      {formik.errors.passwordConfirmation ? (
                        <div className="text-danger">
                          {formik.errors.passwordConfirmation}
                        </div>
                      ) : null}
                    </div>

                    <IFCondition cond={events.failed}>
                      <div
                        className="alert alert-warning fade show mt-4"
                        role="alert" >
                        <strong>
                          <i className="fa fa-check-circle"></i>
                          &nbsp;Operación fallida.
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </strong>
                        <br/>
                        {events.failed}
                      </div>
                    </IFCondition>

                    <button
                      className="btn btn-lg btn-primary btn-block btn-login mb-2 mt-5"
                      hidden={events.loading}
                      type="submit" >
                      Enviar
                    </button>

                    <Link
                      className="btn btn-sm btn-link btn-block"
                      to="/auth/login"  >
                      Volver al login
                    </Link>
                  </form>
                </div>
              </div>
              <footer className="page-footer font-small pt-5 ">
                <div className="footer-copyright text-center text-muted py-3">
                  © Cencosud {getYear()}. Todos los derechos reservados.
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <Loading visible={events.loading} />
    </div>
  );
};

export default Recover;
