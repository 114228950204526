import React, { useRef } from "react";
import { useModalChangePassword } from "./hooks";
import IFCondition from "../../../components/Commons/IFCondition/IFCondition";

const ModalChangePassword = () => {
  const btnModal = useRef();
  const { formik, errorMessage } = useModalChangePassword(btnModal);
  return (
    <div
      className="modal fade"
      id="change-pass"
      tabIndex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true" >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Cambio de contraseña
            </h5>
          </div>
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputPasswordOld">Contraseña actual</label>
                <input
                  type="password"
                  className="form-control"
                  id="previewPassword"
                  name="previewPassword"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.previewPassword}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPasswordNew">Nueva contraseña </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password ? (
                  <div className="text-danger">{formik.errors.password}</div>
                ) : null}
                <small className="form-text text-muted">
                  Tu contraseña debe tener entre 8 y 12 caracteres
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="confirmNewPassword">
                  Verificar nueva contraseña{" "}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                />
                {formik.errors.confirmPassword ? (
                  <div className="text-danger">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
                <span className="form-text small text-muted">
                  Para confirmar, escriba la nueva contraseña nuevamente.
                </span>
              </div>
            </div>
            <IFCondition cond={errorMessage}>
              <div className="alert alert-warning">{errorMessage}</div>
            </IFCondition>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                ref={btnModal}
                data-dismiss="modal">
                Cancelar
              </button>
              <button type="submit" className="btn btn-success">
                Confirmar cambio
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalChangePassword;
