import React from 'react'
import { fullDate } from '../../../utils/dateForm'

const HeaderForm = ({ children }) => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 15)
  const dateCancel = fullDate(currentDate)
  return (
    <div>
      <div className="card shadow">
        <div className="card-header formulario-header px-5">
          <span>Paso 1 de 3</span>
          <h1 className="display-4">
            Regístrate en&nbsp;
            <img
              src={require('../../../assets/img/WongPrime1.1.png')}
              alt="jumprime"
              width="150px"
              style={{ margin: '0 auto', marginLeft: '-3%' }}
            />
          </h1>
        </div>
        <div className="card-body px-5 pb-5">
          <div
            className="card mb-3 bg-light"
            style={{
              border: '1px solid #fdf0d6',
              boxShadow: '0 0 2rem 0 rgba(136, 152, 170, .2)',
            }}>
            <div className="card-body">
              Inicia tu periodo de prueba{' '}
              <b>
                sin costo por{' '}
                {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                  <>
                    <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                    {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                  </>
                ) : (
                  `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                )}{' '}
              </b>{' '}días.
              <br />
              <span style={{ color: '#ec1c24' }}>
                Luego solo {process.env.REACT_APP_MEMBERSHIP_PRICE} por{' '}
                {process.env.REACT_APP_MEMBERSHIP_DURATION} de beneficios.
              </span>
              <br></br>
              <span>
                {/* Redeploy por actualización de variable REACT_APP_VALIDITY_CAMPAIGN *2 */}
                *Vigencia de la campaña del {process.env.REACT_APP_VALIDITY_CAMPAIGN}. Stock mínimo
                promocional: 5,000 promociones. Terminada la vigencia de la campaña el precio de
                suscripción será
                {process.env.REACT_APP_MEMBERSHIP_PRICE_FUTURE} cada{' '}
                {process.env.REACT_APP_MEMBERSHIP_DURATION}.
              </span>
            </div>
          </div>
          {/* <p><small>* En caso que no quieras realizar este pago puedes cancelar tu suscripción hasta el {dateCancel}.</small></p> */}
          {children}
        </div>
      </div>
    </div>
  )
}

export default HeaderForm
