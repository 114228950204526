import * as Yup from "yup";

export const handleValidation = () => {
    return Yup.object().shape({
      code: Yup.string().required("El código es equerido"),
      password: Yup.string()
        .required("La contraseña es requerida")
        .min(8, "La contraseña debe tener mas de 8 caracteres")
        .max(12, "La contraseña debe tener un máximo de 12 caracteres")
        .matches(
          /^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Contraseña debe tener al menos una letra mayuscula, minuscula y un número"
        )
        .oneOf(
          [Yup.ref("passwordConfirmation")],
          "Las contraseñas no son iguales"
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
    });
};