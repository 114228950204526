import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { enableUserAction, logoutAction, setAuthData } from '../../../redux/actions/LoginActions'
import { AppDispatch } from '../../../redux/utils/AppDispatch'
import { dniTypes } from './constans'
import {} from '../../../redux/actions/RegisterPayUActions'
import { securityPayNiubizAction } from '../../../redux/actions/RegisterPayNiubizActions'
import { getParameters } from '../../../services/Register'
import {
  _REGISTER_END_LOADING,
  _REGISTER_START_LOADING,
} from '../../../redux/actions/RegisterActions'

export const usePersonalInfo = () => {
  const [oldValues, setOldValues] = useState({
    oldDni: null,
    validDni: true,
    active: false,
    status: null,
  })
  const { errorMessage, isLoading } = useSelector((state) => state.register)
  const [parameters, setParameters] = useState({
    dniTypes: [],
    genders: [],
    departments: [],
    cities: [],
  })

  useEffect(() => {
    AppDispatch(_REGISTER_START_LOADING())
    Promise.all([getParameters('dniTypes'), getParameters('genders')])
      .then(([dni, gender]) => {
        setParameters({
          dniTypes: dni.data.data.data,
          genders: gender.data.data.data,
        })
      })
      .finally(() => {
        AppDispatch(_REGISTER_END_LOADING())
      })
  }, [])

  return { isLoading, errorMessage, oldValues, setOldValues, parameters }
}

export const useParametersAddress = () => {
  const [parameters, setParameters] = useState({
    departments: [],
    cities: [],
    districts: [],
  })

  useEffect(() => {
    Promise.all([
      getParameters('departments'),
      getParameters('cities', `reference=15`),
      getParameters('districts', `reference=26`),
    ]).then(([department, cities, districts]) => {
      setParameters({
        departments: department.data.data.data,
        cities: cities.data.data.data,
        districts: districts.data.data.data,
      })
    })
  }, [])

  const searchCities = (city) => {
    if (city) {
      getParameters('cities', `reference=${city}`).then((res) => {
        setParameters({ ...parameters, cities: res.data.data.data })
      })
    }
  }

  const searchDistricts = (districts) => {
    if (districts) {
      getParameters('district', `reference=${districts}`).then((res) => {
        setParameters({ ...parameters, districts: res.data.data.data })
      })
    }
  }

  return { parameters, searchCities, searchDistricts }
}

export const useRegister = () => {
  const navigate = useNavigate()
  const [oldValues, setOldValues] = useState({
    oldDni: null,
    oldEmail: null,
    validDni: true,
    validEmail: true,
    active: false,
  })
  const { errorMessage, data, isLoading } = useSelector((state) => state.register)

  const [showPass, setShowPass] = useState({
    pass: false,
    confirmPass: false,
  })

  useEffect(() => {
    if (data && !data.data.needCard && data.data.isPremium) {
      AppDispatch(setAuthData(data))
      navigate('/auth/register-premium-success', { state: { data: data.data } })
    } else if (data) {
      AppDispatch(setAuthData(data))
      localStorage.setItem('dataUser', JSON.stringify(data.data))
      navigate('/auth/register-save', { state: { data: data.data } })
    }
  })

  return { isLoading, errorMessage, oldValues, setOldValues, showPass, setShowPass }
}

export const useRegisterSave = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [info, setInfo] = useState({
    benefit_start_date: null,
    benefit_end_date: null,
    names: null,
    email: null,
    dni: null,
    payment_date: null,
    cancel_date: null,
    subscription_period: null,
    is_active: null,
    dni_type: null,
    status: null,
  })

  const toPayNiubiz = () => {
    //console.log("Niubiz Hook");
    AppDispatch(securityPayNiubizAction(info, false))
  }

  const closeSesion = () => {
    AppDispatch(logoutAction())
    navigate('/auth/login')
  }

  useEffect(() => {
    if (state) {
      console.log('state: ', state)
      
      setInfo({
        benefit_start_date: state.data.benefit_start_date,
        benefit_end_date: state.data.benefit_end_date,
        name: state.data.first_name,
        last_name: state.data.last_name,
        email: state.data.email,
        dni: state.data.dni,
        payment_date: state.data.payment_date,
        cancel_date: state.data.benefit_end_date,
        subscription_period: state.data.subscription_period,
        is_active: state.data.is_active,
        dni_type: dniTypes[state.data.dni_type],
        status: state.data.status,
      })
    }
    /*if(!state){
      navigate("/auth/login");
    }*/
  }, [state])

  return { info, toPayNiubiz, closeSesion }
}

export const useRegisterPremiumSave = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [info, setInfo] = useState({
    benefit_start_date: null,
    benefit_end_date: null,
    name: null,
    last_name: null,
    email: null,
    dni: null,
    cancel_date: null,
    dni_type: null,
  })

  const toPayNiubiz = () => {
    //console.log("Niubiz Hook");
    AppDispatch(securityPayNiubizAction(info, false))
  }

  const closeSesion = () => {
    AppDispatch(logoutAction())
    navigate('/auth/login')
  }

  useEffect(() => {
    if (state) {
      setInfo({
        benefit_start_date: state.data.benefit_start_date,
        benefit_end_date: state.data.benefit_end_date,
        name: state.data.first_name,
        last_name: state.data.last_name,
        email: state.data.email,
        dni: state.data.dni,
        cancel_date: state.data.benefit_end_date,
        dni_type: state.data.dni_type,
      })
    }
    /*if(!state){
      navigate("/auth/login");
    }*/
  }, [state])

  return { info, toPayNiubiz, closeSesion }
}

export const useRegisterSuccess = () => {
  const { state } = useLocation()
  const [showEffect, setShowEffect] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (state) {
      AppDispatch(enableUserAction())
      AppDispatch(logoutAction())
      setTimeout(() => {
        setShowEffect(true)
      }, 500)
      setTimeout(() => {
        setShowEffect(false)
      }, 4000)
    } /*else {
      navigate("/auth/login");
    }*/
  }, [state, navigate])

  return { state, showEffect }
}

export const useRegisterCoupon = () => {
  const [dni, setDni] = useState('')
  const { state } = useLocation()
  useEffect(() => {
    setDni(state?.data?.dni)
  }, [state])

  return { dni }
}

export const useRegisterSaveCoupon = (info) => {
  const { data, errorMessage } = useSelector((state) => state.coupon)
  const [benefit_end_date, setBenefit_end_date] = useState('')
  const [payment_date, setPayment_date] = useState('')
  const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]

  useEffect(() => {
    window.$('#validate-coupon').modal('show')
    if (info.status !== null && info.status !== 6 && info.status !== 7) {
      window.$('#validate-coupon').modal('hide')
    }
  }, [info.status])

  useEffect(() => {
    if (info.benefit_end_date) {
      setBenefit_end_date(info.benefit_end_date)
      setPayment_date(info.payment_date)
    }
    if (modalBackdrop) {
      modalBackdrop.style.display = 'none'
    }
    if (data.couponDate) {
      setBenefit_end_date(data.couponDate.benefit_end_date.replace(/-/g, '/'))
      setPayment_date(data.couponDate.payment_date.replace(/-/g, '/'))
    }
  }, [modalBackdrop, info, data])
// update benefit?end?date
  useEffect(() => {
    if (data?.couponDate) {
      window.$('#validate-coupon').modal('hide')
      setBenefit_end_date(data.couponDate.benefit_end_date.replace(/-/g, '/'))
      setPayment_date(data.couponDate.payment_date.replace(/-/g, '/'))
    }
  }, [data])

  return { benefit_end_date, payment_date, errorMessage, data }
}